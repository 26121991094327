import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import LoginReducer from './apps/Login/LoginSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    auth: LoginReducer,
  },
});

export default store;
