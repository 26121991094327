import { lazy } from 'react';
import Loadable from '../layouts/loader/Loadable';
import { Navigate } from 'react-router-dom';

/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));

/***** Pages ****/

const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
const ProjectDashboard = Loadable(
  lazy(() => import('../views/dashboard/ProjectDashboard/ProjectDashboard')),
);
// const DashboardFilters = Loadable(lazy(() => import('../views/dashboard/Filters/Filters')));

/***** Project Details Page *****/

const ProjectDetails = Loadable(lazy(() => import('../../src/pages/ProjectDetails/ProjectDetails')));
const ViewAllProjects = Loadable(lazy(() => import('../../src/pages/ViewAllProjects/ViewAllProjects')));
const CreateProject = Loadable(lazy(() => import('../../src/pages/CreateProjects/CreateProject')));
const Issues = Loadable(lazy(() => import('../../src/pages/Issues/Issues')));
const Comments = Loadable(lazy(() => import('../../src/pages/Issues/Comments')));
const AllIssues = Loadable(lazy(() => import('../../src/pages/Issues/AllIssues')));

/***** Auth Pages ****/

const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));
const ForgotPassword = Loadable(lazy(() => import('../views/auth/ForgetPassword')));
const ResetPassword = Loadable(lazy(() => import('../views/auth/ResetPassword')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const VerifyEmail = Loadable(lazy(() => import('../views/auth/VerifyEmail')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));


/***** Invite Pages *****/

const CreateClientUser = Loadable(lazy(() => import('../components/CreateClientUser/CreateClientUser')));
const InviteTeam = Loadable(lazy(() => import('../components/InviteTeam/InviteTeam')));
const InviteTeamDetails = Loadable(lazy(() => import('../components/InviteTeam/InviteTeamMembers')));
const InviteHistory = Loadable(lazy(() => import('../components/InviteTeam/InviteHistory')));

const ThemeRoutes = (isLoggedIn) => {
  return [
    { path: '/', element: <LoginFormik /> },
    { path: '/Zm9yZ290cGFzc3dvcmQ=', element: <ForgotPassword /> },
    { path: '/cmVzZXRwYXNzd29yZA==/:userid', element: <ResetPassword /> },
    { path : '/UmVjb3ZlclBhc3N3b3Jk', element: <RecoverPassword />},
    { path: '/UmVnaXN0ZXJGb3JtaWs=/:inviteToId', element: <RegisterFormik /> },
    { path: '/VmVyaWZ5RW1haWw=/:regID', element: <VerifyEmail /> },
    {
      path: '/',
      element: isLoggedIn ? <FullLayout /> : <Navigate to="/" />,
      children: [
        { path: '/RGFzaGJvYXJk', element: <Dashboard /> },
        { path: '/SW52aXRlVGVhbQ==/:inviteToId', element: <InviteTeam /> },
        { path: '/SW52aXRlVGVhbURldGFpbHM=', element: <InviteTeamDetails /> },
        { path: '/SW52aXRlSGlzdG9yeQ==', element: <InviteHistory /> },
        { path: '/Vmlld0FsbFByb2plY3Rz', element: <ViewAllProjects /> },
        { path: `/SXNzdWVz/:projectID`, element: <Issues /> },
        { path: `/Q29tbWVudHM=/:issueID`, element: <Comments /> },
        { path: '/Q3JlYXRlUHJvamVjdA==', element: <CreateProject /> },
        { path: '/UHJvamVjdERhc2hib2FyZA==', element: <ProjectDashboard /> },
        { path: '/UHJvamVjdERldGFpbHM=/:projectID', element: <ProjectDetails /> },
        { path: '/Q3JlYXRlQ2xpZW50VXNlcg==', element: <CreateClientUser />},
        { path: '/QWxsSXNzdWVz',element : <AllIssues />}
      ],
    },
  ];
};

export default ThemeRoutes;

// /cmVzZXRwYXNzd29yZA== -- reset password
// UmVjb3ZlclBhc3N3b3Jk  -- recover password
// { path: '/RmlsdGVycw==', element: <DashboardFilters /> },
