
import React, { Suspense } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import './index.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import APILIST from './utils/GlobalApi';
import ApiProvider from '../src/services/ApiProvider'
import { useState, useEffect } from 'react';

const App = () => {

  const [lastActive, setLastActive] = useState(new Date());
  const navigate = useNavigate()

  const isNull = (value) => {
    return value === null || value === "" || value === undefined
  }

  const isLoggedIn = () => {
    let userInfo = JSON.parse(localStorage.getItem("userInformation"));
    if (!isNull(userInfo)) {
      let loggedinTime = new Date(userInfo[0].loggedInTime)
      let currentTime = new Date();
      let timeDifference = currentTime - loggedinTime;
      let timeDifferenceInMinutes = timeDifference / (1000 * 60);
      if (timeDifferenceInMinutes > 4) {
        updatingJWTtoken(userInfo[0]);
      }
      return true
    } else {
      return false;
    }
  }

  const updatingJWTtoken = async (userInfo) => {
    try {
      let request = {
        body: {
          token: userInfo.Token,
        },
      };
      const res = await ApiProvider.auth(APILIST.API_URL_FOR_REFRESH_TOKEN, request)
      if (res[0]?.DML_STATUS === "S") {
        const userinfo = JSON.parse(localStorage.getItem("userInformation"));
        userinfo[0].Token = res != null ? res[0].Token : '';
        userinfo[0].loggedInTime = Date();
        localStorage.setItem("userInformation", JSON.stringify(userinfo));
      } else {
        navigate('/')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleLogout = () => {
    navigate('/')
  }

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("userInformation"));
    if (!isNull(userInfo)) {
      const intervalId = setInterval(() => {
        const currentTime = new Date();
        const idle = currentTime - lastActive;
        const interval = 5 * 60 * 1000;
        isLoggedIn()
        if (idle > interval) {
          localStorage.setItem("isLastActive", JSON.stringify(Date()))
          handleLogout();
          clearInterval(intervalId);
        }
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [lastActive, handleLogout]);

  const handleUserActivity = () => {
    setLastActive(new Date());
  };

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("userInformation"));
    if (!isNull(userInfo)) {

      document.addEventListener('mousemove', handleUserActivity);
      document.addEventListener('keypress', handleUserActivity);

      return () => {
        document.removeEventListener('mousemove', handleUserActivity);
        document.removeEventListener('keypress', handleUserActivity);
      };
    }
  }, [handleUserActivity]);

  const routing = useRoutes(Themeroutes(isLoggedIn()));
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);

  return (
    <Suspense fallback={<Loader />}>
      <div
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}>
        <ToastContainer />
        <ThemeSelector />
        {routing}
      </div>
    </Suspense>
  );
};

export default App;