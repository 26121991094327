const APILIST = {

  // Login Api
  AUTH_LOGIN_API: '/general/auth/login',
  API_URL_TO_FORGET_PASSWORD: '/general/auth/forgetPassword',
  API_URL_FOR_CHECK_PREVIOUS_PASSWORD: '/userPassword/checkPassword',
  API_URL_TO_CHANGE_PASSWORD: '/general/user/transactions',
  API_FOR_VERIFY_USER_PASSWORD:'/general/views/checkPassword',

  //Email Api
  API_FOR_VERIFY_USER_EMAIL: '/general/user/transactions',
  API_URL_FOR_DATA_DUPLICATE_VALIDATION :'/general/checkEmail/ATS_AIM_CHECK_DUPLICATE',

  //Saving Api

  API_URL_FOR_SAVING_USER_DETAILS: '/general/user/transactions',
  API_URL_FOR_CREATE_PROJECT:'/projects/project/transactions',
  API_URL_FOR_PROJECT_TEAM_INVITE :'/projects/projectTeam/transactions',

  // Removing Api
  API_URL_FOR_REMOVE_PROJECT_TEAM_MEMBER :'/projects/projectTeam/transactions',
  API_URL_FOR_DELITING_PROJECT : '/projects/project/transactions',
  API_URL_FOR_DELITING_USER : '/general/user/transactions',


  //  Issue Api
  API_FOR_CREATE_ISSUE:'/issue/createIssue/transactions',
  API_URL_FOR_INSERTING_ATTACHMENT: '/general/attachment/transactions',
  API_URL_FOR_ISSUSE_TO_ASSIGNEE: '/issue/assignIssue/transactions',
  API_URL_FOR_LINKED_ISSUE_IN_ISSUE :'/issue/linkedIssue/transactions',
  API_URL_FOR_WORKFLOW_VIEW:'/issue/views/ATS_AIM_WORKFLOW_V',
  API_URL_FOR_DOWNLOADING_ATTACHMENT:'/general/attachment/download/',

  //View Api
  API_URL_VIEWS_USER: '/general/views/ATS_AIM_USER_V',
  API_URL_FOR_INVITED_USERS:'/invite/views/ATS_AIM_INVITED_USER_V',
  API_URL_VIEWS_ROLES: '/general/views/ATS_AIM_ROLE_V',
  API_URL_FOR_VIEWS_PROJECTS:'/projects/views/ATS_AIM_PROJECTS_V',
  API_URL_FOR_VIEWS_PROJECTS_TEAMS:'/projects/views/ATS_AIM_PROJECTS_TEAM_V',
  API_FOR_INVITE_LOOKUP_VALUES: '/invite/views/ATS_AIM_USER_ROLE_V',  // not used
  API_FOR_CREATE_ISSUE_LOOKUP_VALUES: '/general/views/ATS_AIM_LOOKUP_V',
  API_URL_FOR_PROJECTS_VIEWS_JOINED_USER:'/projects/views/ATS_AIM_PROJECTS_V',
  API_URL_FOR_VIEWS_ATTACHMENT: '/general/views/ATS_AIM_ATTACHMENT_V', 
  API_URL_FOR_EMAIL_HISTORY_VIEW: '/general/views/ATS_AIM_EMAIL_HISTORY_V',
  // Notification Api 
  API_URL_FOR_NOTIFICATION_COUNT:'/projects/views/NOTIFICATION_COUNT',
  
  // Views Issues
  API_URL_FOR_VIEW_ISSUE:'/issue/views/ATS_AIM_ISSUES_V' ,
  API_URL_FOR_VIEW_LINKED_ISSUE:'/issue/views/ATS_AIM_LINKED_ISSUES_V' ,
  API_URL_FOR_ISSUE_STATUS_VIEW:'/issue/views/ATS_AIM_ISSUES_STATUS_LOG_V',
  API_URL_FOR_ISSUE_ASSIGNMENT_VIEW:'/issue/views/ATS_AIM_ISSUE_ASSIGNMENT_V',
  API_URL_FOR_ISSUE_WATCHERS_VIEW:'/issue/views/ATS_AIM_ISSUE_WATCHERS_V',
  API_URL_FOR_LINKED_ISSUES_PARENT:'/issue/views/ATS_AIM_PARENT_LINKED_ISSUES_V',

  // Comment view
  API_URL_FOR_COMMENTS_VIEW:'/issue/views/ATS_AIM_ISSUE_COMMENTS_V',
  API_URL_FOR_COMMENTS_REACTION_VIEW:'/issue/views/ATS_AIM_ISSUE_COMMENTS_REACTION_V',
  API_URL_FOR_INSERTING_COMMENT:'/issue/issueComments/transactions',
  API_URL_FOR_COMMENTS_HISTORY_VIEW:'/issue/views/ATS_AIM_ISSUES_HISTORY_V',
  API_URL_FOR_ALL_COMMNETS_VIEW:'/issue/views/ATS_AIM_HISTORY_COMMENT_V',

  // Invite Users
  API_URL_INVITE_USERS: '/invite/inviteUser/transactions',
  
  // Watchers Api
  API_URL_FOR_ADDING_WATCHER: '/issue/issueWatchers/transactions',
  API_URL_FOR_VIEW_ISSUE_WATCHER : '/issue/views/ATS_AIM_ISSUE_WATCHERS_V',

  // Update Api
  API_URL_FOR_UPDATIN_ROLE:'/general/updateRole/transactions',
  API_URL_FOR_REFRESH_TOKEN:"/general/auth/refresh",
};
export default APILIST;
