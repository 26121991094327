import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    userInfo: {}, 
    userToken: null, 
    error: null,
    success: false, 
    
  }

  const LoginSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    
  })
  
  export default LoginSlice.reducer